@import url(https://fonts.googleapis.com/css?family=Noto+Sans);

body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
}

/**  custom bootstrap - start */
.btn {
  border-radius: 25px;
  border-color: #ddd;
}

.btn:hover {
  border: solid 1px #bbb;
  background-color: #fff;
}

.btn:active {
  background-color: #f9f9f9;
  border: solid 1px #bbb;
  outline: none;
}

.btn:disabled {
  background-color: #f9f9f9;
  border: solid 1px #ddd;
  color: #bbb;
}

.btn:focus:active,
.btn:focus,
.btn:active {
  outline: none;
}

.open > .dropdown-toggle.btn-default {
  background-color: #fff;
}

.dropdown-menu {
  top: 25px;
  padding: 3px 0;
  border-radius: 2px;
  border: 1px solid #bbb;
}

.dropdown-menu > li > a {
  padding: 9px 12px;
  cursor: pointer;
}

.dropdown-menu > li > a:hover {
  background-color: rgba(81, 92, 230, 0.05);
  color: #333;
}

.bi15 {
  width: 15px;
  height: 15px;
}
/** custom fontawesome - end */

.calendar-icon {
  width: 14px;
  height: 14px;
}

#top {
  height: 49px;
  border-bottom: 1px solid #bbb;
  padding: 12px;
  font-size: 10px;
}

#top .logo img {
  top: 5px;
  position: absolute;
  height: 28px;
  height: 38px;
}

#lnb {
  position: absolute;
  width: 200px;
  top: 49px;
  bottom: 0;
  border-right: 1px solid #d5d5d5;
  padding: 12px 10px;
  background: #fafafa;
}

#right {
  position: absolute;
  left: 200px;
  top: 49px;
  right: 0;
  bottom: 0;
}

#lnb label {
  margin-bottom: 0;
  cursor: pointer;
}

.lnb-new-schedule {
  padding-bottom: 12px;
  border-bottom: 1px solid #e5e5e5;
}

.lnb-new-schedule-btn {
  height: 100%;
  font-size: 14px;
  background-color: #ff6618;
  color: #ffffff;
  border: 0;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
}

.lnb-new-schedule-btn:hover {
  height: 100%;
  font-size: 14px;
  background-color: #e55b15;
  color: #ffffff;
  border: 0;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
}

.lnb-new-schedule-btn:active {
  height: 100%;
  font-size: 14px;
  background-color: #d95614;
  color: #ffffff;
  border: 0;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
}

.lnb-calendars > div {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: normal;
}

.lnb-calendars-d1 {
  padding-left: 8px;
}

.lnb-calendars-d1 label {
  font-weight: normal;
}

.lnb-calendars-item {
  min-height: 14px;
  line-height: 14px;
  padding: 8px 0;
}

.lnb-footer {
  color: #999;
  font-size: 11px;
  position: absolute;
  bottom: 12px;
  padding-left: 16px;
}

#menu {
  padding: 16px;
}

#dropdownMenu-calendarType {
  padding: 0 8px 0 11px;
}

#calendarTypeName {
  min-width: 62px;
  display: inline-block;
  text-align: left;
  line-height: 30px;
}

.move-today {
  padding: 0 16px;
  line-height: 30px;
}

.move-day {
  padding: 8px;
  font-size: 0;
}

#renderRange {
  padding-left: 12px;
  font-size: 19px;
  vertical-align: middle;
}

.dropdown-menu-title .calendar-icon {
  margin-right: 8px;
}

.calendar-bar {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
  border: 1px solid #eee;
  vertical-align: middle;
}

.calendar-name {
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
}

.schedule-time {
  color: #005aff;
}

#calendar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  top: 64px;
}

/** custom fontawesome */
.fa {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.weekday-grid-more-schedules {
  float: right;
  margin-top: 4px;
  margin-right: 6px;
  height: 18px;
  line-height: 17px;
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 12px;
  text-align: center;
  color: #000;
}
